.modal {
  max-width: 800px;
  &-title {
    text-align: center;

    &-icon {
      font-size: 84px;
      color: #e2e3e3;
    }
  }

  &-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    background: #f8fafb;
    padding: 20px 30px 30px 30px;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
}
