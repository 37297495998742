.container {
  background: var(--white);
  border-top: 1px solid #e7eaec;
  padding: 10px 20px;
  font-size: 13px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
