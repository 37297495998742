.accordion {
  background-color: var(--white);
  margin-bottom: 25px;
}

.allocations {
  background-color: var(--white);
  padding: 15px 20px 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
