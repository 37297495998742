.container {
  display: flex;
  gap: 10px;

  a {
    color: black;
  }
}

.modal {
  justify-content: flex-end;
  margin-top: 20px;
}
