.grid {
  display: grid;
  grid-template-columns: 90px 70px 1fr 1.5fr 1fr 1fr 35px 1.75fr 1.5fr 70px;
  border-bottom: 1px solid #929292;

  & > span {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .label {
    padding-right: 5px;
  }
}

.grid-short {
  grid-template-columns: 90px 70px 1fr 1fr 1fr 1px 35px 1.75fr 1.5fr 70px;
}
