.stream {
  position: relative;
  padding: 10px 0;

  .stream-badge {
    width: 50px;

    &-icon {
      border: 1px solid #e7eaec;
      border-radius: 50%;
      padding: 6px;
      color: #808486;
      position: absolute;
      background-color: var(--white);
      left: 8px;
    }

    .current-activity {
      color: var(--white);
      background-color: var(--primary);
      border-color: var(--primary);
    }
  }

  .stream-body {
    margin-left: 55px;

    .stream-info {
      display: flex;
      gap: 10px;
    }

    .btns {
      display: flex;
      gap: 10px;
    }
  }
}
