.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;

  .title {
    font-size: 24px;
  }

  .button {
    margin: 5px;
  }
}
