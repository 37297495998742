.link {
  text-decoration: none;
  color: #a7b1c2;
  padding: 14px 20px 14px 25px;
  border-left: 4px solid transparent;

  &:hover,
  &.active {
    background: #293846;
    color: var(--white);
    border-left-color: var(--primary);
  }

  .icon {
    margin-right: 6px;
  }
}
