.form-header {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.calculation {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mismatch {
  color: var(--danger);
}
