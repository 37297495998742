.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.grid {
  display: grid;
  grid-template-columns: 90px 70px 1fr 1.5fr 1fr 1fr 35px 1.75fr 1.5fr 70px;
  border-bottom: 1px solid #929292;

  & > span {
    display: flex;
    align-items: center;
    padding: 10px;
    overflow-wrap: anywhere;
    word-break: normal;
  }

  .label {
    padding-right: 5px;
  }

  & > span:last-of-type {
    padding: 10px 0;
  }
}

.grid-short {
  grid-template-columns: 90px 70px 1fr 1fr 1fr 1px 35px 1.75fr 1.5fr 70px;
}

.empty {
  padding-top: 15px;
  padding-bottom: 20px;
  text-align: center;
}

.claim {
  background-color: #e5e4e2;
}

.warning {
  color: var(--danger);
  font-weight: bold;
}
