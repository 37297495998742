.modal {
  min-width: 900px;

  &-title {
    text-align: center;
  }

  p {
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    font-family: inherit;
  }
}
