.modal {
  max-width: 800px;
  &-title {
    text-align: center;
  }
}

.body {
  display: grid;
  gap: 30px;
  background: #f8fafb;
  padding: 20px 30px 30px 30px;
}
