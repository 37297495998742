.container {
  padding: 15px;

  .header {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: 20px;
    }
  }

  .separator {
    padding: 0 0 0 5px;
  }

  .button {
    padding: 5px;
  }
}
