.modal {
  max-width: 800px;
  max-height: 95vh;
  overflow-y: auto;
  &-title {
    text-align: center;

    p {
      font-size: 12px;
    }
  }
}

.body {
  background: #f8fafb;
  padding: 20px 30px 30px 30px;

  .form {
    display: grid;
  }

  .table tr:last-of-type {
    font-weight: bold;
  }
}
