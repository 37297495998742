.danger {
  color: var(--danger);
}

.icon {
  padding: 5px;

  &:hover {
    cursor: pointer;
  }
}
