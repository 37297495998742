.container {
  padding: 15px;

  .header {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    .button-wrapper {
      text-align: right;
    }
  }

  .button {
    padding: 5px;
  }
}
