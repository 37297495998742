.body {
  background: #f8fafb;
  padding: 20px 30px 30px 30px;
  margin: 0 -30px;

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
  }
}
