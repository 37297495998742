.table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 10px 10px 0;
    text-align: right;
  }

  .grey {
    background-color: #f4f4f4;
  }
}
