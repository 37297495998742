.container {
  line-height: 1.5;

  .edit {
    color: #b45400;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .header-title {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      a {
        margin-top: 24px;
      }
    }
  }

  .activities {
    display: flex;
    gap: 20px;
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
