.container {
  padding: 15px 0;

  .block {
    padding-bottom: 20px;
  }

  .row {
    display: flex;
    gap: 10px;
  }

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 25px;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .positive {
      color: #00813d;
    }
    .negative {
      color: #d50707;
    }
  }
}
