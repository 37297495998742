.container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;
  margin-bottom: 1.5rem;

  .align-center {
    text-align: center;
  }
}

.align-right {
  text-align: end;
}

@media (max-width: 576px) {
  .container {
    grid-auto-flow: row;
  }
}
