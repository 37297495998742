.card {
  color: var(--primary);
  border-radius: 0.5rem;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 25px 20px;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 0.5rem;
  }

  a:hover {
    color: var(--white);
    background-color: var(--primary);
  }

  h3 {
    margin-bottom: 0;
  }

  .header {
    margin-right: 10px;
  }
}
