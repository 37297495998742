.wrapper {
  position: sticky;
  height: 100vh;
  top: 0;
  background: #2f4050;
  transition: all 0.4s;

  &-shown {
    width: 70px;
  }

  &-hidden {
    width: 0px;

    & > .navigation a {
      display: none;
    }
  }

  .header {
    padding: 33px 25px;
    background-image: url('assets/header-profile.png');

    .logo {
      display: flex;
      justify-content: center;
    }

    .dropdown {
      background-color: transparent !important;
      border: none;
      font-size: 14px;
      font-weight: 400;
      color: #8095a8 !important;

      &:active,
      &:hover,
      &:focus {
        color: #8095a8 !important;
        box-shadow: none !important;
      }
    }
  }

  .short-logo {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    padding: 18px 0;
  }

  .navigation {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (width > 576px) {
  .wrapper {
    &-shown {
      width: 220px;
    }

    &-hidden {
      width: 70px;

      & > .navigation a {
        display: block;
      }
    }
  }
}
