.row {
  margin: 0 25px 10px;

  table {
    tr {
      height: 55px;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 20px;

  .item:last-of-type {
    text-align: right;

    button {
      background-color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
  }
}
