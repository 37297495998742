.modal {
  max-width: 800px;
  &-title {
    text-align: center;

    p {
      font-size: 12px;
    }
  }
}

.body {
  background: #f8fafb;
  padding: 20px 30px 30px 30px;

  .table tr:last-of-type {
    font-weight: bold;
  }
}

.footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}
