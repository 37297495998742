.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: var(--white);

  .burger {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;

    &:hover,
    &:active {
      box-shadow: none !important;
    }
  }

  .button {
    color: #999c9e !important;
    font-size: 14px !important;
    font-weight: normal !important;

    &:hover {
      background-color: var(--white) !important;
      border-color: var(--white) !important;
      box-shadow: none !important;
    }
  }
}
