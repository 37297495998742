.modal {
  max-width: 500px;
  &-title {
    text-align: center;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
