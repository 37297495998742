.container {
  padding: 15px 20px 20px;
  background-color: var(--white);
  display: grid;
  gap: 25px;

  .table {
    width: 75%;
  }
}
